import React, { useEffect, useState } from "react";
import Footer from "../components/Footer";
import Navbars from "../components/navbar/Navbars";
import PostDetailsWithSidebar from "../components/post-details-with-sidebar";
import StaffInFocus from "../components/StaffInFocus_device_view/StaffInFocus";
import "../styles/blog.css";
import Spinner from "react-bootstrap/Spinner";
import Article_index from "../components/article_card_device_view/article_index";
import axios from "axios";
import * as ReactBootstrap from 'react-bootstrap';
import FooterNew from "../components/footer_new";



function Blog() {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(false);


  const fetch = async () => {
    try{
      const data = await axios 
      .get(`${process.env.REACT_APP_BASE_URL}/posts`,
      {
        headers: {
          // "ngrok-skip-browser-warning": "69420",
 //         Authorization: `Bearer ${process.env.AUTH_TOKEN}`,
        },
      })
      .then((res) => {
        setData(res.data);
        
      });
      setLoading(true);
    }
    catch(e){
      console.log(e);
    }
  }
  

  useEffect(() => {
      fetch();
  }, []);


  return (
    <div className="bgblog">
      <Navbars />
      {loading ? (
       <>
            <div className="animate__animated animate__fadeInRight blog-session">
            <PostDetailsWithSidebar sidebarPosition={"left"} />
            <StaffInFocus />
            {/* <Articles /> */}
            <Article_index/>
            {/* <Footer onLanguageChange={(v) => fetch()} /> */}
            <FooterNew/>
          </div>
          </>
         
      ) : (
        <>
        <div className=" h-100">
              <div className="position-absolute top-50 start-50 translate-middle">
                <div className="">
                <ReactBootstrap.Spinner animation="border"/>
                </div>
              </div>
            </div>
            </>
  )}
  </div>
)}


export default Blog;
